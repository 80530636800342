import React, { Fragment } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import { timeAgo } from '../utils/formatters';



const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: '450px',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);


const styles = makeStyles((theme) => ({
    root: {
    },
    notesWrapper: {
        maxHeight: '60vh',
        overflowY: 'scroll'
    },
    comment: {
        margin: '10px 10px 10px 5px',
        width: '40%'
    },
    commentDate: {
        fontSize: '12px',
    },
    commentDivider: {
        paddingLeft: '5px',
        paddingRight: '10px'
    },
    title: {
      textAlign: 'center',
      paddingTop: '10px'
    }
}));

const CommentPreviewTooltip = (props) => {
  const classes = styles();
  const commentData = props.comments;

    return (
          <HtmlTooltip interactive={true}
            title={
              <React.Fragment>
                <Typography color="inherit" className={classes.title}><b><u>Comments</u></b></Typography>
                <div className={classes.notesWrapper}>
                        <List className={classes.root}>
                            {
                                commentData.sort((a,b) => a.date < b.date ? 1 : -1).map(currentComment => {
                                    return (
                                        <Fragment key={currentComment.id}>
                                            <Divider variant="inset" component="li" />
                                            <ListItem alignItems="flex-start">
                                                <ListItemAvatar>
                                                    <Avatar alt={currentComment.commenter} src="/does/not/exist/1.jpg" />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Fragment>
                                                            <Typography 
                                                                component="span" 
                                                                className={`${classes.inline}`}
                                                            >
                                                                {currentComment.commenter} -
                                                            </Typography> 
                                                            <Typography 
                                                                component="span" 
                                                                variant="body2"
                                                                className={`${classes.inline} ${classes.commentDivider}`}
                                                            >
                                                                {currentComment.category}
                                                            </Typography> 
                                                            <Typography
                                                                component="span"
                                                                className={`${classes.inline} ${classes.commentDate}`}
                                                            >
                                                             {`(${timeAgo(currentComment.date)})`}
                                                            </Typography>
                                                        </Fragment>
                                                    }
                                                    secondary={
                                                        <Typography
                                                            component="span"
                                                            variant="body1"
                                                            className={classes.commentBody}
                                                            color="textPrimary"
                                                        >
                                                            {currentComment.comment}
                                                        </Typography>
                                                        
                                                    }
                                                />
                                            </ListItem>
                                        </Fragment>
                                    );
                                })
                            }
                        </List>
                    </div>
              </React.Fragment>
            }
          >
            <Badge color="primary" badgeContent={props.count} max={9} overlap="rectangular">
              <ChatBubbleIcon />
            </Badge>
          </HtmlTooltip>
      );
}

export default CommentPreviewTooltip;