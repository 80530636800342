import React, { memo, Fragment } from 'react';
import { LoanService } from '../../services';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import WatchLater from '@material-ui/icons/WatchLater';

const AddMarkAsProcessingButton = ({selectedRowIndices, payments}) => {
      const handleProcessingClick = () => {
        LoanService.markPaymentsAsProcessing(selectedRowIndices.map(rowId => {
          return payments[rowId].id;
        })).then(response => {
          // !FIXME: Do this better!
          window.location.reload();
        });
      }

      return (
          <Fragment>
              <Tooltip title={`Mark Payments as 'Processing'`}>
                <IconButton onClick={handleProcessingClick}>
                  <WatchLater />
                </IconButton>
              </Tooltip>
        </Fragment>
      );
  }

export default memo(AddMarkAsProcessingButton);
