import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useNotification } from '../contexts/NotificationContext';
import ConfirmationModal from '../components/ConfirmationModal';
import green from '@material-ui/core/colors/green';
import { LoanService } from '../services';


const styles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    resendButton: {
        backgroundColor: green[400],
        marginTop:'4px',
        marginRight:'4px',
        textTransform: 'none', 
    }
}));

const ResendNotificationCell = (props) => {
    const classes = styles();
    const { createErrorNotification } = useNotification();
    const [showResendConfirmationBox, setShowResendConfirmationBox] = useState(false);
    const toggleResendConfirmation = () => setShowResendConfirmationBox(!showResendConfirmationBox);

    const handleResendButtonClick = async (event) => {
        toggleResendConfirmation();
    }

    const handleResendButton = async () => {
        resendSingleNotification(props.notificationId);
        toggleResendConfirmation();
    }

    const resendSingleNotification = async (notificationId) => {
        try {
            const response = await LoanService.resendNotification(notificationId);

            if (response.status && response.status === 200) {
                // TODO: Improve me
                setTimeout(() => window.location.reload(), 100)
            } else {
                createErrorNotification("Failed to resend notification");
            }
        } catch (err) {
            createErrorNotification("Failed to resend notification");
        }
    }

    return (
        <>
            <div className={classes.container}>
                <ConfirmationModal title="Confirm" message="Are you sure you want to resend this notification?" onConfirm={handleResendButton} onCancel={toggleResendConfirmation} showConfirmationBox={showResendConfirmationBox}/>
                <Button variant="contained" 
                    className={classes.resendButton} 
                    onClick={handleResendButtonClick}
                    > Resend {props.resendDate && (<><br />({props.resendDate})</>)}
                </Button>
            </div>
        </>
    );
}

export default ResendNotificationCell;