/* eslint eqeqeq: "off" */
import React, { useState, useEffect, memo } from 'react';
import { isConciergeCell, isDelinquentCell, isHeldCell } from '../../utils/cellRenderers';
import { FundingStagesCell, FlagCell, LoanTypeIcon } from '../../components';
import { LoanService, DealerService, LenderService } from '../../services';
import { useNotification } from '../../contexts/NotificationContext';
import { formatDate, centsToDollar, aprToPercent, sortDollarColumn, formatCreditScore, promoToColor, promosToChips } from '../../utils/formatters';
import { saveTableSession, getTableSession } from '../../utils/sessionHelpers';
import { getLoanStatusColor, LOAN_STATUSES } from '../../utils/constants';
import MUIDataTable from 'mui-datatables';
import { debounceSearchRender } from 'mui-datatables';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Badge from '@material-ui/core/Badge';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InlineLoadingText from '../../components/InlineLoadingText';

const ROWS_PER_PAGE = 50;

// NOTE: ANY CHANGES TO ORDER HERE REFLECTED IN utils/formatters
const getColumns = (filterList, visibleColumns, dealerData, orgData, lenderData, setAllDealers, page, maxPage, setCurrentlyShownDealers, currentlyShownDealers) => [
  {
      name: 'Loan ID',
      options: {
          filter: false,
          display: visibleColumns ? visibleColumns.includes('Loan ID') : true,
          customBodyRender: (value, tableMeta) => {
            return <a href={`/lms/loans/${value}`}>{value}</a>
          }
      }
  },
  {
      name: 'Status',
      options: {
        filter: true,
        filterType: 'multiselect',
        filterOptions: {'names': LOAN_STATUSES},
        filterList: (filterList ? filterList[1] : []),
        display: visibleColumns ? visibleColumns.includes('Status') : true,
        customBodyRender: (value, tableMeta) => {
          return <Chip 
            label={value?.toUpperCase()} 
            style={{fontWeight: 'bold', fontSize: '12px', backgroundColor: getLoanStatusColor(value)}}
          />
        }
      }
  },
  {
    name: 'Boarded Date',
    options: {
        filter: false,
        display: visibleColumns ? visibleColumns.includes('Boarded Date') : true
    }
  },
  {
    name: 'Amount',
    options: {
        filter: false,
        sortCompare: sortDollarColumn,
        display: visibleColumns ? visibleColumns.includes('Amount') : true
    }
  },
  {
    name: 'APR',
    options: {
      filterList: (filterList ? filterList[4] : []),
      display: visibleColumns ? visibleColumns.includes('APR') : true
    }
  },
  {
    name: 'Term',
    options: {
      filterList: (filterList ? filterList[5] : []),
      display: visibleColumns ? visibleColumns.includes('Term') : true
    }
  },
  {
    name: 'Dealer Payments',
    options: {
        filter: false,
        sort: false,
        display: visibleColumns ? visibleColumns.includes('Dealer Payments') : true
    }
  },
  {
    name: 'Comments',
    options: {
        filter: false,
        display: visibleColumns ? visibleColumns.includes('Comments') : true,
        /*ignore jslint start*/
        customBodyRender: (value) => {
          return (value == 0) ? null : (<Badge color="primary" badgeContent={value} max={9}>
                  <ChatBubbleIcon />
                </Badge>)
        }
        /*ignore jslint end*/
    }
  },
  {
    name: 'Dealer',
    options: {
      filterType: 'custom',
      filterOptions: {
        display: (filterList) => {
          return (
            <FormControl>
              <Autocomplete
              autoHighlight
              options={dealerData}
              getOptionLabel={(option) => option}
              onChange={(event, value) => filterList[8] = [value]}
              renderInput={(params) => <TextField {...params} label={"Dealer"} />}>              
              </Autocomplete>
            </FormControl>
          )
        }
      },
      filterList: (filterList ? filterList[8] : []),
      display: visibleColumns ? visibleColumns.includes('Dealer') : true
    }
  },
  {
    name: 'Manual Flag',
    options: {
      filter: false,
      sort: false,
      display: visibleColumns ? visibleColumns.includes('Manual Flag') : true,
      // Note: dealerNumber comes from diff column on each table...that's why this isn't in cellRenderers
      customBodyRender: (value, tableMeta) => {
        return( 
          // <div style={{pointerEvents: 'none'}}> import red from '@material-ui/core/colors/red';
          <FlagCell value={value} dealerNumber={tableMeta.rowData[15]} flagReason={tableMeta.rowData[16]} disableUpdates={true} />
         
        )
      }
    }
  },
  {
    name: 'Organization',
    options: {
      filterOptions: {'names': orgData},
      filterList: (filterList ? filterList[10] : []),
      display: visibleColumns ? visibleColumns.includes('Organization') : true
    }
  },
  {
    name: 'Lender',
    options: {
      filterOptions: {'names': lenderData},
      filterList: (filterList ? filterList[11] : []),
      display: visibleColumns ? visibleColumns.includes('Lender') : true
    }
  },
  {
    name: 'Borrower',
    options: {
      sort: false,
      filter: false,
      display: visibleColumns ? visibleColumns.includes('Borrower') : true
    }
  },
  {
    name: 'CoBorrower',
    options: {
      sort: false,
      filter: false,
      display: visibleColumns ? visibleColumns.includes('CoBorrower') : true
    }
  },
  {
    name: 'History',
    options: {
        filter: false,
        display: false,
        viewColumns: false
    }
  },
  {
    name: 'Dealer Number',
    options: {
      filterType: 'custom',
      filterList: (filterList ? filterList[15] : []),
      display: visibleColumns ? visibleColumns.includes('Dealer Number') : true
    }
  },
  {
    name: 'Manual Flag Reason',
    options: {
        filter: false,
        display: false,
        viewColumns: false
    }
  },
  {
    name: 'Promos',
    options: {
        filter: true,
        filterType: 'multiselect',
        filterOptions: {'names': ['Staged Funding','APR Buy Down','StipPay','BalPay','OHNO', 'POP']},
        filterList: (filterList ? filterList[17] : []),
        display: visibleColumns ? visibleColumns.includes('Promos') : true,
        customBodyRender: (value) => {
          return value.map( (val, key) => {
              return <Chip label={val} key={key} style={{backgroundColor: promoToColor(val), color: 'white'}} />;
        });
      }
    }
  },
  {
    name: 'Dealer Proceeds',
    options: {
        filter: false,
        display: visibleColumns ? visibleColumns.includes('Dealer Proceeds') : true,
    }
  },
  {
    name: 'Dealer Fee',
    options: {
        filter: false,
        display: visibleColumns ? visibleColumns.includes('Dealer Fee') : true,
    }
  },
  {
    name: 'Concierge Dealer',
    options: {
        filterList: (filterList ? filterList[20] : []),
        filterOptions: {'names': ['true','false']},
        display: visibleColumns ? visibleColumns.includes('Concierge Dealer') : true,
        customBodyRender: isConciergeCell,
        customFilterListOptions: {
          render: v => v ? "Concierge Dealers" : "Non Concierge Dealers",
        },
    }
  },
  {
    name: 'Delinquency',
    options: {
        filterList: (filterList ? filterList[21] : []),
        filterOptions: {'names': ['True', 'False']},
        display: visibleColumns ? visibleColumns.includes('Delinquency') : true,
        customBodyRender: isDelinquentCell,         
        customFilterListOptions: {
          render: v => v === 'True' ? "Delinquent Loans" : "Not Delinquent Loans",
        },
    }
  },
  {
    name: 'On Hold',
    options: {
        filterList: (filterList ? filterList[22] : []),
        filter: true,
        filterOptions: {'names': ['True', 'False']},
        display: false,
        viewColumns: false,
        customFilterListOptions: {
          render: v => v === 'True' ? "On Hold" : "Not On Hold",
        },
    }
  },
  {
    name: 'Stage Funded',
    options: {
        filterList: (filterList ? filterList[23] : []),
        filter: true,
        filterOptions: {'names': ['True', 'False']},
        display: false,
        viewColumns: false,
        customFilterListOptions: {
          render: v => v === 'True' ? "Stage Funded" : "Not Stage Funded",
        },
    }
  },
  {
    name: 'Vision Loan',
    options: {
        filterList: (filterList ? filterList[24] : []),
        filter: true,
        filterOptions: {'names': ['True', 'False']},
        display: false,
        viewColumns: false,
        customFilterListOptions: {
          render: v => v === 'True' ? "Vision Loans" : "Defi Loans",
        },
    }
  },
  {
    name: 'Loan Type',
    options: {
        filterList: (filterList ? filterList[25] : []),
        filter: true,
        sort: false,
        filterOptions: {'names': ['Home Improvement', 'Healthcare', 'Solar']},
        display: visibleColumns ? visibleColumns.includes('Loan Type') : true,
        viewColumns: true,
        customBodyRender: (value) => {
          return (
            <div style={{cursor: 'default', '&:hover': {opacity: 0},}}>
              <LoanTypeIcon style={{cursor: 'default'}} loanType={value} />
            </div>
          )
        },
    }
  },
  {
    name: 'Return Issued',
    options: {
        filterList: (filterList ? filterList[26] : []),
        filter: true,
        filterOptions: {'names': ['True', 'False']},
        display: false,
        viewColumns: false,
        customFilterListOptions: {
          render: v => v === 'True' ? "Return Issued" : "No Returns",
        },
    }
  },
  {
    name: 'Status Code',
    options: {
      filterList: (filterList ? filterList[27] : []),
      filter: true,
      sort: false,
      filterOptions: {'names': ['CURRENT', 'AUTO PAY', 'PIF', 'LOW BALANCE', 'SPANISH', 'NOCALL', 'NOCOMM', 'CLAIM FILED', 'NO BILLING', 'INCR', 'DECR', 'LAM', 'BANKRUPTCY', 'BORROWER DECEASED', 'LAM COMPLETE', 'PIC', 'DO NOT REPORT', 'CBR DELETE']},
      display: visibleColumns ? visibleColumns.includes('Status Code') : true,
      viewColumns: true
    }
  },
  {
    name: 'Credit Score',
    options: {
        sort: true,
        display:  visibleColumns ? visibleColumns.includes('Credit Score') : true,
    }
  },
  {
    name: 'Portfolio',
    options: {
        sort: true,
        display:  visibleColumns ? visibleColumns.includes('Portfolio') : true,
        filterList: (filterList ? filterList[29] : []),
        filterOptions: {'names': ['CX', 'AH', 'INTERNAL', 'CW', 'FCC', 'VS', 'VS-ESG']},
        filter: true,
        viewColumns: true
    }
  }
];

const getTableOptions = (createErrorNotification, setTableConfig, count, page, sortOrder, searchText, filterList) => {
    return {
        serverSide: true,
        filterType: 'dropdown',
        selectableRows: 'none',
        print: false,
        download: true,
        count: count,
        rowsPerPage: ROWS_PER_PAGE,
        rowsPerPageOptions: [],
        page: page,
        sortOrder: sortOrder,
        searchText: searchText,
        confirmFilters: true,
        customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
          return (
            <div style={{ marginTop: '40px' }}>
              <Button variant="contained" onClick={() => changeFilter(setTableConfig, applyNewFilters)}>Apply Filters</Button>
            </div>
          );
        },
        onFilterChange: (column, filterList, type) => {
          if (type === 'chip') {
            // Chip is removed
            var newFilters = () => (filterList);
            changeFilter(setTableConfig, newFilters);
          }
        },
        jumpToPage: true,
        customSearchRender: debounceSearchRender(2000),
        onRowClick: rowData => {
            // const appId = rowData[0];
        },
        onTableChange: (action, tableState) => {
          switch (action) {
            case 'changePage':
              changePage(setTableConfig, tableState.page);
              break;
            case 'sort':
              changeSort(setTableConfig, tableState.sortOrder);
              break;
            case 'search':
              changeSearch(setTableConfig, tableState.searchText);
              break;
            // NOTE: 'filterChange case is handled above in onFilterChange (for chips) and 
            // by clicking the Apply Filters button of customFilterDialogFooter
            case 'resetFilters':
              changeFilter(setTableConfig, []);
              break;
            case 'viewColumnsChange':

              let displayedVisibleColumns = tableState.columns.map(column => {
                if (column.display === "true") {
                  return column.name;
                } else {
                  return null;
                }
              }).filter(column => column !== null);
              setTableConfig(state => ({
                ...state,
                visibleColumns: displayedVisibleColumns
              }));
              if (sessionStorage.getItem("loansTable")) {
                saveTableSession(displayedVisibleColumns, "loansTable");
              }

              break;
            default:
              break;
          }

        },
        expandableRows: true,
        expandableRowsHeader: false,
        isRowExpandable: (dataIndex, expandedRows) => {
            // if (dataIndex === 3 || dataIndex === 4) return false;
    
            // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
            if (expandedRows.data.length > 4 && expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0) return false;
            return true;
          },
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            return (
              <TableRow>
                <TableCell colSpan={colSpan}>
                <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell>Is Held?</TableCell>
                    <TableCell>Hold Comment</TableCell>
                    <TableCell>Payment Stage</TableCell>
                    <TableCell>Payment Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {rowData[14].map((historyRow) => (
                    <TableRow key={historyRow.id}>
                      <TableCell component="th" scope="row">
                        {historyRow.date}
                      </TableCell>
                      <TableCell>{historyRow.type.toLowerCase() === 'funding request' ? 'Pull from Credit Union' : historyRow.type}</TableCell>
                      <TableCell align="right">{historyRow.amount}</TableCell>
                      <TableCell>{isHeldCell(historyRow.isHeld)}</TableCell>
                      <TableCell>{historyRow.holdComment}</TableCell>
                      <TableCell>{historyRow.paymentStage}</TableCell>
                      <TableCell>{historyRow.paymentType === 'nacha' ? 'ACH' : historyRow.paymentType}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
                </TableCell>
     
              </TableRow>
            );
          },
          onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => console.log(curExpanded, allExpanded, rowsExpanded)
    };
};

const processHistoryRecords = (historyRecords) => {
  return historyRecords ? historyRecords.map(record => {
    record.date = formatDate(record.date);
    record.amount = centsToDollar(record.amount);
    record.apr = aprToPercent(record.apr);

    return record;
  }) :
  [];
}

const tablifyLoans = (loans, isLoading) =>
  isLoading ? null :
  loans.map(loan => {
      return [
        loan.loanNumber,
        loan.loanStatus,
        formatDate(loan.created),
        centsToDollar(loan.amount),
        aprToPercent(loan.apr),
        loan.term,
        FundingStagesCell({"hasStagedFunding": loan.hasStagedFunding, "paidStages": loan.paidStages, "heldStages": loan.heldStages, "hasForcedPayment": loan.hasForcedPayment, "numberOfPaymentStages": loan.numberOfPaymentStages}),
        loan.commentCount,
        loan.dealer?.name,
        loan.dealer?.isManualFlagged,
        loan.dealer?.organization?.name,
        loan.lender?.shortName,
        loan.applicant?.fullName,
        loan.coapplicant?.fullName,
        processHistoryRecords(loan.history),
        loan.dealer?.dealerNumber,
        loan.dealer?.manualFlaggedReason,
        promosToChips(loan),
        centsToDollar(loan.dealerNetProceeds),
        centsToDollar(loan.totalDealerHoldback),
        loan.dealer?.isConcierge,
        loan.daysDelinquent,
        loan.isHeld,
        loan.hasStagedFunding,
        loan.isVision,
        loan.loanType,
        loan.returnIssued,
        loan.loanStatusCode,
        formatCreditScore(loan.creditScorePB, loan.creditScoreCB),
        loan.portfolioCode
      ];
  });


const changePage = (setTableConfig, page) => {
  setTableConfig(state => ({
    ...state,
    page: parseInt(page),
  }));
}

const changeSort = (setTableConfig, sortOrder) => {
  setTableConfig(state => ({
    ...state,
    page: 0,  // Sorting resets pagination
    sortOrder: sortOrder,
  }));
}

const changeSearch = (setTableConfig, searchText) => {
  // Note: Clear out the page and sort state first
  setTableConfig(state => ({
    ...state,
    searchText: searchText,
    page: 0,  // Searching resets pagination
    sortOrder: {},
  }));
}

const changeFilter = (setTableConfig, applyFilters) => {
  let filterList = applyFilters();

  // Note: Clear out the page and sort state first
  setTableConfig(state => ({
    ...state,
    filterList: filterList,
    page: 0,  // Filtering resets pagination
    sortOrder: {},
  }));
}

var initialFilters = (() => {
  var filters = []
  if (localStorage.getItem("dealerNumber")) {
    const dealerNumber = localStorage.getItem("dealerNumber");
    if (localStorage.getItem("delinquency")) {
      filters = [[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[dealerNumber],[],[],[],[],[], ["True"]];
    }
    else if (localStorage.getItem("loanStatusCode")) {
      const loanStatusCode = localStorage.getItem("loanStatusCode");
      filters = [[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[dealerNumber],[],[],[],[],[],[],[],[],[],[],[], loanStatusCode ? [loanStatusCode] : []];
    }
    else {
      filters = [[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[dealerNumber]];
    }
    localStorage.clear();
  }
  return filters;
})();

const LoansTable = () => {
    const [tableConfig, setTableConfig] = useState({
      page: 0,
      count: 0,
      rowsPerPage: ROWS_PER_PAGE,
      sortOrder: {},
      searchText: '',
      filterList: initialFilters,
      visibleColumns: ['Loan ID', 'Status', 'Booking Date', 'Amount', 'Term', 'Dealer Payments', 'Funding', 'Dealer', 'Manual Flag', 'Organization', 'Lender', 'Borrower', 'Delinquency','Dealer Number'],
      data: [['Loading Data...']],
      isLoading: false,
    });
    const [allDealers, setAllDealers] = useState({
      data: [],
      page: 0,
    });
    const [allLenders, setAllLenders] = useState({
      data: [],
    });
    const [allOrgs, setAllOrgs] = useState({
      data: [],
    });
    const [currentlyShownDealers, setCurrentlyShownDealers] = useState({
      startIndex: 0,
      endIndex: 0
    });

    const { createErrorNotification } = useNotification();

    useEffect(() => {
        setTableConfig(state => ({
          ...state,
          isLoading: true,
        }));
        LoanService.getAllVerbose(tableConfig.rowsPerPage, (tableConfig.page * tableConfig.rowsPerPage), tableConfig.sortOrder, tableConfig.searchText, tableConfig.filterList).then(res => {
            if (res) {
              setTableConfig(state => ({
                ...state,
                isLoading: false,
                count: parseInt(res.total),
                data: res.loans,
              }));
              initialFilters = [];
            } else {
              createErrorNotification("Failed to retrieve loans, try refreshing...");
            }
        });
    }, [tableConfig.page, tableConfig.rowsPerPage, createErrorNotification, tableConfig.sortOrder, tableConfig.searchText, tableConfig.filterList]);

    useEffect(() => {
      DealerService.getAllUnique().then(res => {
        if(res){
          setAllDealers({
            data: res.map((dealer) => dealer.legalBusinessName),
            page: 0
          });
          
          setCurrentlyShownDealers({
            startIndex: 0,
            endIndex: ROWS_PER_PAGE
          });
        }
      })
    }, [])
    
    useEffect(() => {
      LenderService.getAll().then(res => {
        if(res){
          setAllLenders({
            data: res.map((lender) => lender.longName)
          });
        }
      })
    }, []);

    useEffect(() => {
      DealerService.getAllOrgs().then(res => {
        if(res){
          setAllOrgs({
            data: res.map((org) => org.organization)
          });
        }
      })
    }, []);

    const options = getTableOptions(createErrorNotification, setTableConfig, tableConfig.count, tableConfig.page, tableConfig.sortOrder, tableConfig.searchText, tableConfig.filterList);
    const loanData = tablifyLoans(tableConfig.data, tableConfig.isLoading);
    tableConfig.visibleColumns = sessionStorage.getItem("loansTable") ? getTableSession("loansTable") : saveTableSession(tableConfig.visibleColumns, "loansTable");


    return (loanData == null ) ? (
        <InlineLoadingText/>
      ) : (
        <MUIDataTable 
          data={loanData} 
          columns={getColumns(tableConfig.filterList, tableConfig.visibleColumns, allDealers.data, allOrgs.data, allLenders.data, setAllDealers, allDealers.page, allDealers.data.length, setCurrentlyShownDealers, currentlyShownDealers)} 
          options={options} 
        />
      );
    
}

export default memo(LoansTable);