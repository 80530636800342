/* eslint eqeqeq: "off" */
import React, { Fragment, memo } from 'react';
import MUIDataTable from 'mui-datatables';
import Chip from '@material-ui/core/Chip';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import Badge from '@material-ui/core/Badge';
import Switch from '@material-ui/core/Switch';
import { dealerNeedsReviewCell, isConciergeCell } from '../../utils/cellRenderers';
import { centsToDollar, sortDollarColumn } from '../../utils/formatters';
import { saveTableSession, getTableSession } from '../../utils/sessionHelpers';
import SettingsApplicationsTwoToneIcon from '@material-ui/icons/SettingsApplicationsTwoTone';
import { memoize } from 'lodash';
import { useRole } from '../../contexts/RolesContext';
import { FlagCell } from '../../components';
import InlineLoadingText from '../../components/InlineLoadingText';

// TODO: Allow filtering by roles. Need custom filtering or rendering logic
// Note: Needed function to pass in functions from props
const getColumns = memoize((toggleShowUpdateDealer, setCurrentDealer, fetchAdditionalMetrics, isDealerBrowser, visibleColumns) => [
  {
      name: 'Dealer ID',
      options: {
        filter: false,
        display: false,
        viewColumns: false
      }
  },
  {
    name: 'Dealer Number',
    options: {
      filter: false,
      display: visibleColumns ? visibleColumns.includes('Dealer Number') : true,
      customBodyRender: (value, tableMeta) => {
        return <a href={`/dealers/${value}`}>{value}</a>
      }
    }
  },
  {
    name: 'Name',
    options: {
        filter: false,
        display: visibleColumns ? visibleColumns.includes('Name') : true
    }
  },
  {
    name: 'Organization',
    options: {
      filter: false,
      display: visibleColumns ? visibleColumns.includes('Organization') : true,
    }
  }, {
    name: 'Affiliate',
    options: {
      filter: false,
      display: visibleColumns ? visibleColumns.includes('Affiliate') : true,
    }
  },
  {
    name: "Account Flag",
    options: {
      display: visibleColumns ? visibleColumns.includes('Account Flag') : true,
      customBodyRender: dealerNeedsReviewCell,
      customFilterListOptions: {
        render: v => v ? "Account Flagged" : "Account Not Flagged",
      },
    }
  },
  {
    name: 'Comments',
    options: {
        filter: false,
        display: visibleColumns ? visibleColumns.includes('Comments') : true,
        /*ignore jslint start*/
        customBodyRender: (value) => {
          return (value == 0) ? null : (<Badge color="primary" badgeContent={value} max={9}>
                  <ChatBubbleIcon />
                </Badge>)
        }
        /*ignore jslint end*/
    }
  },
  {
    name: "Manual Flag",
    options: {
      display: visibleColumns ? visibleColumns.includes('Manual Flag') : true,
      customBodyRender: (value, tableMeta) => {
        return (
          <FlagCell 
            value={value}
            dealerNumber={tableMeta.rowData[1]} 
            flagReason={tableMeta.rowData[18]} 
          />
        );
      },
      customFilterListOptions: {
        render: v => v ? "Manual Flagged" : "Non-Manual Flagged",
      },
    }
  },
  {
    name: "Loan Count",
    options: {
      display: visibleColumns ? visibleColumns.includes('Loan Count') : true,
    }
  },
  {
    name: "Avg Age",
    options: {
      display: fetchAdditionalMetrics,
      viewColumns: fetchAdditionalMetrics,
      filter: false
    }
  },
  {
    name: "Avg Credit",
    options: {
      display: fetchAdditionalMetrics,
      viewColumns: fetchAdditionalMetrics,
      filter: false
    }
  },
  {
    name: "90 Day Credit Score",
    options: {
      display: fetchAdditionalMetrics,
      viewColumns: fetchAdditionalMetrics,
      filter: false
    }
  },
  {
    name: "90 Day Delinquency Rate",
    options: {
      display: fetchAdditionalMetrics,
      viewColumns: fetchAdditionalMetrics,
      filter: false,
        customBodyRender: (value) => {
          return `${value}%`;
        }
    }
  },
  {
    name: "Funded YTD",
    options: {
      sortCompare: sortDollarColumn,
      display: fetchAdditionalMetrics,
      viewColumns: fetchAdditionalMetrics,
      filter: false
    }
  },
  {
    name: "Max Loan Amount",
    options: {
      display: visibleColumns ? visibleColumns.includes('Max Loan Amount') : true,
      sortCompare: sortDollarColumn
    }
  },
  {
    name: "Total Amount Loaned",
    options: {
      display: visibleColumns ? visibleColumns.includes('Total Amount Loaned') : true,
      sortCompare: sortDollarColumn
    }
  },
  {
    name: "Average Loan Amount",
    options: {
      display: visibleColumns ? visibleColumns.includes('Average Loan Amount') : true,
      sortCompare: sortDollarColumn
    }
  },
  {
    name: 'Notification Emails',
    options: {
      filter: false,
      display: visibleColumns ? visibleColumns.includes('Notification Emails') : true,
      customBodyRender: (values) => {

        if (values !== null) {
          return values.map(value => <Chip label={value} />)
        } else {
          return null;
        }
      
      } 
    }
  },
  {
    name: 'Manual Flag Reason',
    options: {
        filter: false,
        display: false,
        viewColumns: false
    }
  },
  {
    name: 'Concierge Dealer',
    options: {
        display: visibleColumns ? visibleColumns.includes('Concierge Dealer') : true,
        customBodyRender: isConciergeCell,
        customFilterListOptions: {
          render: v => v ? "Concierge Dealers" : "Non Concierge Dealers",
        },
    }
  },
  {
    name: 'Config',
    options: {
      filter: false,
      display: visibleColumns ? visibleColumns.includes('Config') : true,
      customBodyRender: (value, tableMeta) => {
        return isDealerBrowser() ? null : (
          <IconButton onClick={() => {
              setCurrentDealer({
                dealerNumber: tableMeta.rowData[1], 
                dealerName: tableMeta.rowData[2],
                routingNumber: tableMeta.rowData[20], 
                accountNumber: tableMeta.rowData[21], 
                notificationEmails: tableMeta.rowData[16]
              });
              toggleShowUpdateDealer();
            }}> 
            <SettingsApplicationsTwoToneIcon fontSize="large" />
          </IconButton>
        );
      } 
    }
  },
  {
    name: 'Routing Number',
    options: {
        filter: false,
        display: false,
        viewColumns: false
    }
  },
  {
    name: 'Account Number',
    options: {
        filter: false,
        display: false,
        viewColumns: false
    }
  },
  {
    name: 'Vision Dealer',
    options: {        
        filter: true,
        display: false,
        viewColumns: false,
        customFilterListOptions: {
          render: v => v ? "Has Vision Loans" : "Non-Vision Dealers",
        },
    }
  },
  {
    name: 'Delinquent Loans',
    options: {        
        filter: false,
        display: visibleColumns ? visibleColumns.includes('Delinquent Loans') : true,
        viewColumns: true,
    }
  },
  {
    name: 'Delinquency Rate',
    options: {        
        filter: false,
        display: visibleColumns ? visibleColumns.includes('Delinquency Rate') : true,
        viewColumns: true,
        customBodyRender: (value) => {
          return `${value}%`;
        }
    }
  },
  {
    name: 'Delinquent for 30+ Days Rate',
    options: {        
        filter: false,
        display: visibleColumns ? visibleColumns.includes('Delinquent for 30+ Days Rate') : true,
        viewColumns: true,
        customBodyRender: (value) => {
          return `${value}%`;
        }
    }
  },
  {
    name: 'Loan Types',
    options: {        
        filter: true,
        filterType: 'dropdown',
        display: false,
        viewColumns: false,
        filterOptions: {
          logic: (value, filters) => {
            if(filters.includes('Home Improvement')) {
              return !value[2] > 0;
            }if(filters.includes('HealthCare')) {
              return !value[1] > 0;
            }if(filters.includes('Solar')) {
              return !value[0] > 0;
            }
          },
          names: [ 'HealthCare','Home Improvement','Solar']
        }
    }
  },
  {
    name: 'Create Date',
    options: {        
        filter: false,
        display: visibleColumns ? visibleColumns.includes('Create Date') : true,
        viewColumns: true,
    }
  },
  {
    name: 'Test Trxns',
    options: {        
        filter: true,
        display: visibleColumns ? visibleColumns.includes('Test Trxns') : true,
        viewColumns: true,
    }
  }
]);

  const getTableOptions = (toggleShowBulkUpload, toggleShowNewDealer, toggleFetchAdditionalMetrics, isDealerBrowser) => {
    return {
        filterType: 'dropdown',
        selectableRows: 'none',
        print: false,
        download: false,
        expandableRowsHeader: false,
        onTableChange: (action, tableState) => {
          switch (action) {
            case 'viewColumnsChange':
              let displayedVisibleColumns = tableState.columns.map(column => {
                if (column.display === "true") {
                  return column.name;
                } else {
                  return null;
                }
              }).filter(column => column !== null);
              if (sessionStorage.getItem("dealerTable")) {
                saveTableSession(displayedVisibleColumns, "dealerTable");
              }

              break;
            default:
              break;
          }
        },
        customToolbar: () => {
          return (
            <Fragment>
            <AddDealerToolbarButton toggleShowBulkUpload={toggleShowBulkUpload} toggleShowNewDealer={toggleShowNewDealer} isDealerBrowser={isDealerBrowser} />
            <FetchAdditionalMetricsToolbarButton toggleFetchAdditionalMetrics={toggleFetchAdditionalMetrics}/>
            </Fragment>
          );
        }
    };
};

// const isActiveCell = value => {
//     if (value === true) {
//         return "Yes";
//     } else {
//         return "No";
//     }
// }

const tablifyDealers = dealers =>
  dealers == null ? null :
    dealers.map(dealer => {
      return [
        dealer.id,
        dealer.dealerNumber,
        dealer.legalBusinessName,
        dealer.organization,
        dealer.affiliate,
        dealer.needsReview,
        dealer.commentCount,
        dealer.isManuallyFlagged,
        dealer.totalFundedLoans ? parseInt(dealer.totalFundedLoans) : dealer.totalFundedLoans,
        dealer.avgAge,
        dealer.creditScores,
        dealer.ninetyDayAvgCreditScore,
        dealer.ninetyDayDelinquencyRate,
        centsToDollar(dealer.fundedYTD),
        centsToDollar(dealer.maxLoanAmount),
        centsToDollar(dealer.totalLoanAmount),
        centsToDollar(dealer.averageLoanAmount),
        dealer.notificationEmails,
        dealer.manualFlaggedReason,
        dealer.isConcierge,
        null,
        dealer.routingNumber,
        dealer.accountNumber,
        dealer.hasVisionLoan,
        dealer.totalDelinquentLoans,
        dealer.delinquencyRate,
        dealer.delinquentOverThirtyDayRate,
        dealer.loanTypes,
        dealer.created,
        dealer.testTransStatus,
      ];
  });

// https://github.com/gregnb/mui-datatables/issues/83
const AddDealerToolbarButton = ({ toggleShowBulkUpload, toggleShowNewDealer, isDealerBrowser }) => {
    const handleNewDealerClick = () => {
        toggleShowNewDealer();
    }

    const handleBulkUploadClick = () => {
        toggleShowBulkUpload();
    }

    return isDealerBrowser() ? null : (
        <Fragment>
          <Tooltip title={"Add New Dealer"}>
              <IconButton onClick={handleNewDealerClick}> 
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Bulk Dealer Upload"}>
            <IconButton onClick={handleBulkUploadClick}>
              <CloudUploadIcon />
            </IconButton>
          </Tooltip>
      </Fragment>
    );
}

const FetchAdditionalMetricsToolbarButton = ({ toggleFetchAdditionalMetrics }) => {

  const handleFetchAdditionalMetricsClick = () => {
      toggleFetchAdditionalMetrics();
  }

  return (
      <Fragment>
          <Tooltip title={"Fetch Delinquency & Funding Data"}>
          <Switch onChange={handleFetchAdditionalMetricsClick}>
          </Switch>
        </Tooltip>
    </Fragment>
  );
}

const DealersTable = ({ dealers, toggleShowBulkUpload, toggleShowNewDealer, toggleShowUpdateDealer, toggleFetchAdditionalMetrics, fetchAdditionalMetrics, setCurrentDealer }) => {  
    const { isDealerBrowser } = useRole();  
    const options = getTableOptions(toggleShowBulkUpload, toggleShowNewDealer, toggleFetchAdditionalMetrics, isDealerBrowser);
    const dealerData = tablifyDealers(dealers);
    const starterColumns = ['Dealer Number', 'Name', 'Organization', 'Account Flag', 'Comments', 'Manual Flag', 'Loan Count', 'Max Loan Amount', 'Total Amount Loaned', 'Average Loan Amount', 'Notification Emails', 'Concierge Dealer', 'Config'];
    let visibleColumns = sessionStorage.getItem("dealerTable") ? getTableSession("dealerTable") : saveTableSession(starterColumns, "dealerTable");
    
    return dealerData == null ? (
        <InlineLoadingText/>
      ) : (
        <MUIDataTable data={dealerData} columns={getColumns(toggleShowUpdateDealer, setCurrentDealer, fetchAdditionalMetrics, isDealerBrowser, visibleColumns)} options={options} />
      );
}

export default memo(DealersTable);