import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DealerService } from '../../services';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import ChipInput from 'material-ui-chip-input';
import Tooltip from '@material-ui/core/Tooltip';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { useNotification } from '../../contexts/NotificationContext';
import { useRole } from '../../contexts/RolesContext';

function isBlank(value) {
  return value == null || value.trim().length === 0;
}

function updateRequiredField(value, updater, errorUpdater) {
  if (isBlank(value)) {
    errorUpdater(true);
    updater(value);
    return true;
  } else {
    errorUpdater(false);
    updater(value);
    return false;
  }
}

function updateField(value, updater, errorUpdater) {
  updater(value);
  return false;
}

const useStyles = makeStyles((theme) => ({
  paper: {
      position: 'absolute',
      left: "20%",
      top: "15%",
      width: '60vw',
      height: '75vh',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflowY: 'scroll',
  },
  modalButton: {
      marginLeft: '15px',
      float: 'right',
  },
  coreForm: {
    height: '50vh'
  },
  lockIcons: {
   
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  lockedDiv: {
    backgroundColor: 'gainsboro',
    marginTop: '5vh',
    marginBottom: '5vh',    
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  unlockedDiv: {
    marginTop: '5vh',
    marginBottom: '5vh',    
    paddingLeft: '10%',
    paddingRight: '10%',
  },
}));

const nineDigitRegex = RegExp(/^\d{9}$/g);

const UpdateDealer = (props) => {
    const classes = useStyles();
    const [dealerId, setDealerId] = useState(null);
    const [dealerIdError, setDealerIdError] = useState(false);
    const [legalBusinessName, setLegalBusinessName] = useState(null);
    const [legalBusinessNameError, setLegalBusinessNameError] = useState(false);
    const [bankAccountNumber, setBankAccountNumber] = useState(null);
    const [bankAccountNumberError, setBankAccountNumberError] = useState(false);
    const [bankRoutingNumber, setBankRoutingNumber] = useState(null);
    const [bankRoutingNumberError, setBankRoutingNumberError] = useState(false);
    const [organization, setOrganization] = useState(null);
    const [affiliate, setAffiliate] = useState(null);
    const [organizationError, setOrganizationError] = useState(false);
    const [affiliateError, setAffiliateError] = useState(false);
    const [notificationEmails, setNotificationEmails] = useState([]);
    const { createErrorNotification } = useNotification();

    const { isAchAdmin } = useRole();

    useEffect(() => {
        setDealerId(props.currentDealer?.dealerNumber);
        setLegalBusinessName(props.currentDealer?.dealerName || null);
        setBankAccountNumber(props.currentDealer?.accountNumber || null);
        setBankRoutingNumber(props.currentDealer?.routingNumber || null);
        setOrganization(props.currentDealer?.organization || null);
        setAffiliate(props.currentDealer?.affiliate || null);
        setNotificationEmails(props.currentDealer?.notificationEmails || []);
    }, [props.currentDealer])

    const resetState = () => {
      setDealerId(null);
      setDealerIdError(false);
      setLegalBusinessName(null);
      setLegalBusinessNameError(false);
      setBankAccountNumber(null);
      setBankAccountNumberError(false);
      setBankRoutingNumber(null);
      setBankRoutingNumberError(false);
      setOrganization(null);
      setOrganizationError(false);
      setAffiliate(null);
      setAffiliateError(false);
      setNotificationEmails([]);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        // First check for missing required fields
        let hasMissingRequiredFields = false;
        hasMissingRequiredFields = updateRequiredField(dealerId, setDealerId, setDealerIdError) || hasMissingRequiredFields;
        hasMissingRequiredFields = updateRequiredField(legalBusinessName, setLegalBusinessName, setLegalBusinessNameError) || hasMissingRequiredFields;
        hasMissingRequiredFields = updateRequiredField(bankAccountNumber, setBankAccountNumber, setBankAccountNumberError) || hasMissingRequiredFields;

        if (hasMissingRequiredFields) {
          createErrorNotification("Missing Required Fields");
        } else if (bankRoutingNumberError || isBlank(bankRoutingNumber)) {
          createErrorNotification("Invalid Bank Routing number. Must be exactly 9 digits.");
        } else {
            try {   
                const response = await DealerService.updateDealer({
                    dealerId: dealerId,
                    legalBusinessName: legalBusinessName,
                    bankAccountNumber: bankAccountNumber,
                    bankRoutingNumber: bankRoutingNumber,
                    dealerGroup: organization,
                    notificationEmails: notificationEmails,
                    affiliate: affiliate,
                });

                if (response.status === 200) {
                    resetState();
                    props.toggleShowUpdateDealer();
                    if (props.callback) {
                      props.callback();
                    }
                } else {
                    throw new Error("Failed to save dealer correctly");
                }
            } catch (err) {
                createErrorNotification("Failed to save Dealer Changes");
            }
        }
    }

    const handleCancel = () => {
      props.toggleShowUpdateDealer();
      resetState();
    }

    const handleDealerIdChange = (event) => updateRequiredField(event.target.value, setDealerId, setDealerIdError)
    const handleLegalBusinessNameChange = (event) => updateRequiredField(event.target.value, setLegalBusinessName, setLegalBusinessNameError)
    const handleBankAccountNumberChange = (event) => updateRequiredField(event.target.value, setBankAccountNumber, setBankAccountNumberError)
    const handleBankRoutingNumberChange = (event) => {
      if (nineDigitRegex.test(event.target.value)) {
        setBankRoutingNumberError(false);
        setBankRoutingNumber(event.target.value);
      } else {
        setBankRoutingNumberError(true);
        setBankRoutingNumber(event.target.value);
      }
    }
    const handleOrganizationChange = (event) => updateField(event.target.value, setOrganization, setOrganizationError);
    const handleAffiliateChange = (event) => updateField(event.target.value, setAffiliate, setAffiliateError);

    const body = (
      <div className={classes.paper}>
          <h2 id="simple-modal-title">Update Dealer</h2>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={5} justifyContent="flex-start">
              <Grid item xs={12}>
                <TextField label="Dealer ID" autoFocus={true} required={true} disabled={true} value={dealerId} error={dealerIdError} onChange={handleDealerIdChange} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField label="Legal Business Name" fullWidth={true} margin="normal" required={true} value={legalBusinessName} error={legalBusinessNameError} onChange={handleLegalBusinessNameChange} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField label="Organization" fullWidth={true} margin="normal" value={organization} error={organizationError} onChange={handleOrganizationChange} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField label="Affiliate" fullWidth={true} margin="normal" value={affiliate} error={affiliateError} onChange={handleAffiliateChange} />
              </Grid>
              <Grid item xs={12}>
                {/* ?TODO: Validate that the input is actually an email? */}
                <ChipInput
                  label="Notification Emails"
                  value={notificationEmails}
                  onAdd={(email) => setNotificationEmails([...notificationEmails, email])}
                  onDelete={(email, index) => setNotificationEmails(notificationEmails.filter(val => val !== email))}
                  fullWidth={true}
                  helperText="Emails that will receive funding notifications"
                />
              </Grid>
              <Grid className={isAchAdmin() ? classes.unlockedDiv : classes.lockedDiv} container spacing={5}>
                <Grid className={classes.lockIcons} item xs={1}>
                  <Tooltip title='Only ACH Admins have access to modifying dealer ACH' placement="top">
                    {isAchAdmin() ? <LockOpenIcon fontSize='large'/> : <LockIcon className={classes.lockIcons} fontSize='large'/>}
                  </Tooltip>
                </Grid>
              <Grid item xs={6}>
                <TextField label="Bank Account Number" fullWidth={true} margin="normal" required={true} value={bankAccountNumber} error={bankAccountNumberError} onChange={handleBankAccountNumberChange} disabled={isAchAdmin() ? false : true}/>
              </Grid>
              <Grid item xs={5}>
                <TextField label="Bank Routing Number" inputProps={{ maxLength: 9 }} fullWidth={true} margin="normal" required={true} value={bankRoutingNumber} error={bankRoutingNumberError} onChange={handleBankRoutingNumberChange} disabled={isAchAdmin() ? false : true}/>
              </Grid>
            </Grid>

              <Grid item xs={12}>
                <Button variant="contained" color="primary" className={classes.modalButton} onClick={handleSubmit}>Save</Button>
                <Button variant="contained" className={classes.modalButton} onClick={handleCancel}>Cancel</Button>
              </Grid>
            </Grid>
          </form>
      </div>
  );

  return (
      <div>
          <Modal
          open={props.showUpdateDealer}
          onClose={props.toggleShowUpdateDealer}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          >
              {body}
          </Modal>
      </div>
  );
}

export default UpdateDealer;