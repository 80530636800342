import React, {useState} from 'react';
import { AccountDropDown } from '../components';
import { RefreshModal } from '../components';
import { P4Logo } from '../components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TodayTwoToneIcon from '@material-ui/icons/TodayTwoTone';
//import AssessmentTwoToneIcon from '@material-ui/icons/AssessmentTwoTone';
import LocalAtmTwoToneIcon from '@material-ui/icons/LocalAtmTwoTone';
// import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import BuildTwoToneIcon from '@material-ui/icons/BuildTwoTone';
import PaymentTwoToneIcon from '@material-ui/icons/PaymentTwoTone';
import PanToolTwoTone from '@material-ui/icons/PanToolTwoTone';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { useRole } from '../contexts/RolesContext';

const drawerWidth = 240;

const styles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.primary.main,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        background: `linear-gradient(#0d47a1, #bbdefb)`,
        width: drawerWidth,
        transition: theme.transitions.create(
            'width', { easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen, }
        ),
    },
    drawerClose: {
        background: `linear-gradient(#0d47a1, #bbdefb)`,
        transition: theme.transitions.create(
            'width', { easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen, }
        ),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: { width: theme.spacing(9) + 1, },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    toolbarLeftSide: {
        width: '100%'
    },
    toolbarRightSide: {
        display: 'flex',
        alignItems: 'center'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        overflowY: "scroll",
    },
}));

const Logo = () => (
    <Link to="/lms/loans" >
        <img
            id="brand-logo"
            alt="PowerPay"
            src={process.env.PUBLIC_URL + `/PowerPay_Logo${process.env.REACT_APP_DEPLOY_ENV === 'production' ? '' : '_QA'}.png`}
        />
    </Link>
);

const LMSMenu = ({isAdmin, isDailyProcessingWrite, isDailyProcessingRead}) => {
    const buttonColor = '#f89d3c'

    const adminOrDpWrite = isAdmin() || isDailyProcessingWrite() || isDailyProcessingRead()

    return (
        <List>
            {
                adminOrDpWrite ? (
                    <ListItem button key="dailyProcessing">
                        <Link to="/lms/daily-processing" style={{ minWidth: 'unset', textDecoration: 'none', display: 'inline-flex', color: buttonColor }} >
                            <ListItemIcon>
                                <TodayTwoToneIcon color="primary"/>
                            </ListItemIcon>
                            <ListItemText primary="Daily Processing" />
                        </Link>
                    </ListItem>
                ) : null
            }
            <ListItem button key="Loans">
                <Link to="/lms/loans" style={{ minWidth: 'unset', textDecoration: 'none', display: 'inline-flex', color: buttonColor }} >
                    <ListItemIcon>
                        <LocalAtmTwoToneIcon color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary="Loans" />
                </Link>
            </ListItem>
        </List>
    );
}

const DealerMenu = (props) => {
    const { isAdmin, isDealerBrowser } = props;

    const buttonColor = '#f89d3c'

    const adminOrDealerBrowser = isAdmin() || isDealerBrowser()

    return (
        <List>
            {
                adminOrDealerBrowser ? (
                    <ListItem button key="dealers">
                        <Link to="/dealers" style={{ minWidth: 'unset', textDecoration: 'none', display: 'inline-flex', color: buttonColor }} >
                            <ListItemIcon>
                                <BuildTwoToneIcon color="primary"/>
                            </ListItemIcon>
                            <ListItemText primary="Dealers" />
                        </Link>
                    </ListItem>
                ) : null
            }
            {
                adminOrDealerBrowser ? (
                    <ListItem button key="test-transactions">
                        <Link to="/dealers/test-transactions" style={{ minWidth: 'unset', textDecoration: 'none', display: 'inline-flex', color: buttonColor }} >
                            <ListItemIcon>
                                <PaymentTwoToneIcon color="primary"/>
                            </ListItemIcon>
                            <ListItemText primary="Test-Trxns" />
                        </Link>
                    </ListItem>
                ) : null
            }
        </List>
    );
}

const AdminMenu = (props) => {
    const {isAdmin, isDailyProcessingWrite, isUserAdmin} = props;

    const buttonColor = '#f89d3c'

    const adminAndDpWrite = isAdmin() && isDailyProcessingWrite()
    const adminAndUserAdmin = isAdmin() && isUserAdmin()

    return (
        <List>
            {
                adminAndDpWrite ? (
                    <ListItem button key="Tools">
                        <Link to="/admin/tools" style={{ minWidth: 'unset', textDecoration: 'none', display: 'inline-flex', color: buttonColor }} >
                            <ListItemIcon><PanToolTwoTone color="primary"/></ListItemIcon>
                            <ListItemText primary="Tool" />
                        </Link>
                    </ListItem>
                ) : null
            }
            {
                adminAndDpWrite ? (
                    <ListItem button key="Fees">
                        <Link to="/admin/fees" style={{ minWidth: 'unset', textDecoration: 'none', display: 'inline-flex', color: buttonColor }} >
                            <ListItemIcon><MonetizationOnOutlinedIcon color="primary"/></ListItemIcon>
                            <ListItemText primary="Fees" />
                        </Link>
                    </ListItem>
                ) : null
            }
            {
                adminAndUserAdmin ? (
                    <ListItem button key="User Admin">
                        <Link to="/admin/users" style={{ minWidth: 'unset', textDecoration: 'none', display: 'inline-flex', color: buttonColor }} >
                            <ListItemIcon><SupervisedUserCircleIcon color="primary"/></ListItemIcon>
                            <ListItemText primary="User Admin" />
                        </Link>
                    </ListItem>
                ) : null
            }
        </List>
    );
}

const PRIMARY_MENU_ITEM = {
    LOS: "LOS",
    LMS: "LMS",
    ADMIN: "ADMIN",
    DEALERS: "DEALERS"
}

const getTabValueFromPath = (path) => {
    // Get the tab value from urls that could have extra paths
    if (path.startsWith("/admin")) {
        return PRIMARY_MENU_ITEM.ADMIN
    } else if (path.startsWith("/dealers")) {
        return PRIMARY_MENU_ITEM.DEALERS
    } else {
        // "/" or "/lms"
        return PRIMARY_MENU_ITEM.LMS
    }
}

const Base = ({history, children}) => {
    const classes = styles();
    const theme = useTheme();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState(getTabValueFromPath(history.location.pathname));

    const { isAdmin, isDailyProcessingWrite, isDailyProcessingRead, isDealerBrowser, isUserAdmin } = useRole();
    const adminOrDealerBrowser = isAdmin() || isDealerBrowser();
    const adminOrDpWrite = isAdmin() || isDailyProcessingWrite();

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: drawerOpen, })} >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                           [classes.hide]: drawerOpen,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className={classes.toolbarLeftSide}>
                        <Tabs aria-label="Primary Navigation Tabs" value={getTabValueFromPath(history.location.pathname)} >
                            <Tab disableRipple style={{minWidth: '70px'}} icon={<P4Logo className={classes.logo}/>} value={PRIMARY_MENU_ITEM.LMS} onClick={() => { setSelectedMenuItem(PRIMARY_MENU_ITEM.LMS); history.push('/lms'); }} />
                            <Tab label="LMS" value={PRIMARY_MENU_ITEM.LMS} onClick={() => { setSelectedMenuItem(PRIMARY_MENU_ITEM.LMS); history.push('/lms'); }} />
                            {
                                adminOrDpWrite ? (
                                    <Tab label="ADMIN" value={PRIMARY_MENU_ITEM.ADMIN} onClick={() => { setSelectedMenuItem(PRIMARY_MENU_ITEM.ADMIN); history.push('/admin'); }} />
                                ) : null
                            }
                            {
                                adminOrDealerBrowser ? (
                                    <Tab label="DEALERS" value={PRIMARY_MENU_ITEM.DEALERS} onClick={() => { setSelectedMenuItem(PRIMARY_MENU_ITEM.DEALER); history.push('/dealers'); }} />
                                ) : null
                            }
                        </Tabs>
                    </div>
                    <div className={classes.toolbarRightSide}>
                        <Logo className={classes.ppBrand} />
                        <AccountDropDown history={history} />
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: drawerOpen,
                    [classes.drawerClose]: !drawerOpen,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: drawerOpen,
                        [classes.drawerClose]: !drawerOpen,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Divider />
                {
                    selectedMenuItem === PRIMARY_MENU_ITEM.ADMIN ?
                        <AdminMenu
                            isAdmin={isAdmin}
                            isDailyProcessingWrite={isDailyProcessingWrite}
                            isUserAdmin={isUserAdmin}
                        /> :
                        selectedMenuItem === PRIMARY_MENU_ITEM.LMS ?
                        <LMSMenu
                            isAdmin={isAdmin}
                            isDailyProcessingWrite={isDailyProcessingWrite}
                            isDailyProcessingRead={isDailyProcessingRead}
                        /> :
                        <DealerMenu
                            isDealerBrowser={isDealerBrowser}
                            isAdmin={isAdmin}
                        />
                }
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Container maxWidth="xl">{children}</Container>
            </main>
            <>{process.env.REACT_APP_DEPLOY_ENV === "LOCAL"} ? {null} : <RefreshModal /></>
        </div>
    );
}

export default withRouter(Base);
